import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss']
})
export class ToastsComponent implements OnInit {

  constructor(private toastService: ToastService) { }

  ngOnInit(): void {
    if(this.toastService.toasts == undefined)
    this.toastService.toasts = [];
  }
  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }
}
