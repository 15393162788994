import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { AppStorage } from 'src/app/core/appstorage';
import { LocalizationService } from 'src/app/services/localization.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConstants } from 'src/app/core/app_constants';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  localization: any;
  UserProfileInfo: any;
  
  constructor(
    @Inject(DOCUMENT) private document: Document, 
    private renderer: Renderer2,
    private router: Router,
    private appStorage: AppStorage,
    private localizationService: LocalizationService,
    private appConstants: AppConstants,
  ) { }

  ngOnInit(): void {
    this.localization = this.localizationService.getFromMap('localization').profile;
    this.UserProfileInfo = this.appStorage.getFromSession('user');

    if(this.UserProfileInfo.profile_photo_path == undefined || this.UserProfileInfo.profile_photo_path == null || this.UserProfileInfo.profile_photo_path == '')
    this.UserProfileInfo.profile_photo_path = 'assets/images/30x30.png';

    else
    this.UserProfileInfo.profile_photo_path = 'assets/'+this.UserProfileInfo.profile_photo_path;
  }

  setLanguage(lang){
    this.appStorage.saveToSession("language", lang);

    this.localizationService.getLocalization(lang).subscribe((response) => {
      this.localizationService.saveToMap('localization',response);
      this.localization = response.profile;
    })
   // this.localizationService.saveToMap('localization',data);
   window.location.reload();
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e) {
    e.preventDefault();
    localStorage.removeItem('isLoggedin');

    this.appStorage.signOut();

    if (!localStorage.getItem('isLoggedin')) {
      this.router.navigate(['/auth/login']);
    }
  }

}
