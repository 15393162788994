import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router,NavigationEnd } from '@angular/router';
import { AppStorage } from './core/appstorage';
import { LocalizationService } from './services/localization.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'nobleui-angular';
  event$;
  currentUrl;
  constructor(
    private appStorage: AppStorage,
    private localizationService: LocalizationService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.event$ = this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationStart) {
        this.currentUrl = event.url;

        if(this.currentUrl != '/auth/login' && this.currentUrl != '/auth/settings'){
          if (this.appStorage.getFromSession('user') == null || this.appStorage.getFromSession('user') == undefined) {
            this.router.navigate(['/auth/login']);
          }
        }
        
      }
    });
  }

  ngOnInit(): void {
    
    if(this.appStorage.getFromSession('language') == null || this.appStorage.getFromSession('language') == undefined){
      this.appStorage.saveToSession('language','de');
    }

    var lang = (this.appStorage.getFromSession("language"))?this.appStorage.getFromSession("language"):'de';
    this.localizationService.getLocalization(lang).subscribe((response) => {
    this.localizationService.saveToMap('localization',response);
    })

  }

}
