import { HttpHeaders } from '@angular/common/http';

export class AppConstants {

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  public localizationPath: string = 'assets/i18n/';

  /*    public serverbaseurl: string = 'http://localhost/directclip_Server/';
     public downloadFile: string = 'http://localhost/directclip_Server/downloadFile.php';
     public locationAttachmentUploadPath: string = 'http://localhost/directclip_Server/upload.php';
     public emailAttachmentUploadPath: string = 'http://localhost/directclip_Server/uploadEmail.php';
     public uploadProfilePath: string = 'http://localhost/directclip_Server/uploadProfile.php';
     public serverPath: string = 'http://localhost/directclip_Server/webservices.php';
     public appEmailUploadPath: string = 'http://localhost:4200/directclipweb/assets/';
     public uploadMedia: string = 'http://localhost/directclip_Server/uploadMedia.php';
     public sendReportEmail: string = 'http://localhost/directclip_Server/cron_job/email.php';
     public uploadedMedia: string = 'assets/media/'; */

  ///////////////// Test dev Version/////////////////////////////
  /* public serverbaseurl: string = 'https://dev.simplyonair.de/directclip_web/directclip_Server/';
  public downloadFile: string = 'https://dev.simplyonair.de/directclip_web/directclip_Server/downloadFile.php';
  public locationAttachmentUploadPath: string = 'https://dev.simplyonair.de/directclip_web/directclip_Server/upload.php';
  public emailAttachmentUploadPath: string = 'https://dev.simplyonair.de/directclip_web/directclip_Server/uploadEmail.php';
  public uploadProfilePath: string = 'https://dev.simplyonair.de/directclip_web/directclip_Server/uploadProfile.php';
  public serverPath: string = 'https://dev.simplyonair.de/directclip_web/directclip_Server/webservices.php';
  public appEmailUploadPath: string = 'https://dev.simplyonair.de/directclipweb/assets/';
  public uploadMedia: string = 'https://dev.simplyonair.de/directclip_web/directclip_Server/uploadMedia.php';
  public sendReportEmail: string = 'https://dev.simplyonair.de/directclip_web/directclip_Server/cron_job/email.php';
  public uploadedMedia: string = 'media/'; */

  ///////////////// Test Beta Version/////////////////////////////
  /*   public serverbaseurl: string = 'https://www.direct-clip.de/testsite/';
    public downloadFile: string = 'https://www.direct-clip.de/testsite/downloadFile.php';
    public locationAttachmentUploadPath: string = 'https://www.direct-clip.de/testsite/upload.php';
    public emailAttachmentUploadPath: string = 'https://www.direct-clip.de/testsite/uploadEmail.php';
    public uploadProfilePath: string = 'https://www.direct-clip.de/testsite/uploadProfile.php';
    public serverPath: string = 'https://www.direct-clip.de/testsite/webservices.php';
    public appEmailUploadPath: string = 'https://www.direct-clip.de/testsite/assets/';
    public uploadMedia: string = 'https://www.direct-clip.de/testsite/uploadMedia.php';
    public sendReportEmail: string = 'https://www.direct-clip.de/testsite/cron_job/email.php';
    public uploadedMedia: string = 'media/'; */

  ///////////////// Live Version/////////////////////////////
  // public serverbaseurl: string = 'https://www.direct-clip.de/live/';
  // public downloadFile: string = 'https://www.direct-clip.de/live/downloadFile.php';
  // public locationAttachmentUploadPath: string = 'https://www.direct-clip.de/live/upload.php';
  // public emailAttachmentUploadPath: string = 'https://www.direct-clip.de/live/uploadEmail.php';
  // public uploadProfilePath: string = 'https://www.direct-clip.de/live/uploadProfile.php';
  // public serverPath: string = 'https://www.direct-clip.de/live/webservices.php';
  // public appEmailUploadPath: string = 'https://www.direct-clip.de/live/assets/';
  // public uploadMedia: string = 'https://www.direct-clip.de/live/uploadMedia.php';
  // public sendReportEmail: string = 'https://www.direct-clip.de/live/cron_job/email.php';
  // public uploadedMedia: string = 'media/';

  ///////////////// Live Root Version/////////////////////////////
  public serverbaseurl: string = 'https://www.direct-clip.de/';
  public downloadFile: string = 'https://www.direct-clip.de/downloadFile.php';
  public locationAttachmentUploadPath: string = 'https://www.direct-clip.de/upload.php';
  public emailAttachmentUploadPath: string = 'https://www.direct-clip.de/uploadEmail.php';
  public uploadProfilePath: string = 'https://www.direct-clip.de/uploadProfile.php';
  public serverPath: string = 'https://www.direct-clip.de/webservices.php';
  public appEmailUploadPath: string = 'https://www.direct-clip.de/assets/';
  public uploadMedia: string = 'https://www.direct-clip.de/uploadMedia.php';
  public sendReportEmail: string = 'https://www.direct-clip.de/cron_job/email.php';
  public uploadedMedia: string = 'media/';

}
