import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';

import { LayoutModule } from './views/layout/layout.module';
import { AuthGuard } from './core/guard/auth.guard';

import { AppComponent } from './app.component';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';

import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { AppConstants } from './core/app_constants';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppStorage } from './core/appstorage';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastsComponent } from './views/toasts/toasts.component';
import { DatePipe } from '@angular/common';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { CookieService } from 'ngx-cookie-service';

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  position: "bottom-right",
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'classic',
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messagelink}}{{compliance}}'
  },
  elements:{
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{link}}</a>,
      <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link settings" id="cookiesSettingsLinkId">{{settingsLink}}</a>
    </span>
    `,
  },
  content: {
    "href": "auth/cookies-privacy",
    privacyPolicyHref: 'auth/cookies-privacy',
    tosettingsHref: 'auth/cookies-settings'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    ToastsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgbToastModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [
    AppConstants,
    FormBuilder,
    AppStorage,
    DatePipe,
    CookieService,
    AuthGuard,
    {
      provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
        }
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
