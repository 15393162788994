import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from '../core/app_constants';
import { ResponseDto } from '../dto/responseDto';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  public storeLocalization: any = new Map();
  constructor(private http: HttpClient,private appConstants: AppConstants) {
    console.log('New Instance Created');
  }

 public getLocalization(language): Observable<any> 
 {
    return this.http.get<any>(this.appConstants.localizationPath+language+'.json');
 }

saveToMap(key: string, value: any): any {
  this.storeLocalization.set(key, value);
}

getFromMap(key: string): any {
 return this.storeLocalization.get(key);
}

deleteData(key: string){
  this.storeLocalization.delete(key);
}

}
